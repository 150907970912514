import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import LinkText from '../../components/Blocks/LinkText/linkText';
import Paragraph from '../../components/Blocks/Paragraph/paragraph';
import Section from '../../components/Blocks/Section/section';
import HeroWithPhotoAndHeader from '../../components/HeroWithPhotoAndHeader/heroWithPhotoAndHeader';
import Layout from '../../components/layout';
import SEO from '../../components/SEO/seo';

const MakeTheSeaAlbumPage = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
    );

  return (
    <Layout backto="/albums" noheader notopmargin >
        <SEO lang="en" title={'Make the Sea'} keywords={['album', 'album review', 'Artyom Alekseev', 'Артём Алексеев', 'аффинаж', 'Сделай море', 'affinage', 'Make the sea']} description="The Railway (Ж/Д) song reminds me of the train station I loved to go to because it was very quiet there. The noise of a passing train was an event.">
        </SEO>
        <HeroWithPhotoAndHeader desc="A beautiful, neat, rhythmic album. Brand new but equally beloved Refining" imageURL="russiansongs-affinage.jpeg" heading="Make the sea('Сделай море')"/>
        <Section >
            <Paragraph>It was quiet on the embankment. Then a light breeze blew and moved the cigarette butt from the stone slab. The wind began to lower him down the steps - first step - second, third, then the wind stopped, and the butt rolled down to the base of the last step.</Paragraph>
            <Paragraph>The sea was also calm, it was gaining momentum gently covering the small stones off the coast. A little further - where the sea was lacking, the stones are much larger.</Paragraph>
            <Paragraph>In the silence, a knock began to be heard, and immediately the second - very rhythmic running. You can use it like a metronome.</Paragraph>
            <Paragraph>At this point, I resort to the seventh song of the album of the same name - "Make the sea" ("Сделай море"). "Dad, Dad, make the sea", such a phrase in my childhood could not be, because we lived right by the sea. So I couldn't have such a cute childish request. But we rarely went there. I don't remember much - just the joy of being with my father</Paragraph>
            <Paragraph>The Railway ("Ж/Д") song reminds me of the train station I loved to go to because it was very quiet there. The noise of a passing train was an event. All people stopped and watched, remembering the times when it was much noisier here. There was also a bridge right above the tracks, where this event could be observed more clearly.</Paragraph>
            <Paragraph>And "Notice Me" ("Заметь меня") is even more significant, it is naive, about childhood. This naivety and chorus made me send this song to my future wife, hoping that she could read between the lines. Now this song is a important memory for us.</Paragraph>
            <Paragraph>This album, if you use Radiohead as a yardstick, is similar to <LinkText href="/en/albums/inrainbows">"In rainbows"</LinkText> in rhythm and structure. It's also wired with money, Radiohead sold the album as pay-as-you-want , and Affinage raised money to record the album on a crowdfunding platform.</Paragraph>
            <Paragraph>Or I just really love these two albums.</Paragraph>
        </Section>


    </Layout>
  ); };

export default MakeTheSeaAlbumPage;